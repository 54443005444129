<script setup lang="ts">
import type { GraphRun } from '@respell/database';
import AppReviewItem from '~/components/app/AppReviewItem.vue';

definePageMeta({
  middleware: [
    function (_, from) {
      if (from.name !== 'inbox') {
        clearNuxtData('inbox/todo');
        clearNuxtData('inbox/completed');
      }
      return;
    },
    function (to, _) {
      if (to.name === 'inbox' && !to.params?.tabId) {
        return navigateTo({
          name: 'inbox',
          params: { tabId: 'todo' },
          replace: true,
        });
      }
      return;
    },
  ],
});

const workspaceStore = useWorkspaceStore();
const tabId = useRouteParams('tabId');

const {
  data: pages,
  pending: isFetchingPages,
  execute: fetchPages,
} = useAsyncCache<GraphRun[], Error>(
  `inbox/${tabId.value}`,
  () => workspaceStore.fetchInboxPages(tabId.value as 'todo' | 'completed'),
  {
    immediate: !!tabId.value,
  },
);

const inboxes = [
  {
    label: 'Needs review',
    to: {
      name: 'inbox',
      params: { tabId: 'todo' },
    },
  },
  {
    label: 'Completed',
    to: {
      name: 'inbox',
      params: { tabId: 'completed' },
    },
  },
];

const handleResume = async (run: GraphRun) => {
  const inputs = Object.fromEntries(
    Object.entries(run.graph.inputs)
      .filter(([_, input]) => input.metadata.forReview === true)
      .map(([key, _]) => [key, run.outputs[key]]),
  );

  await workspaceStore.approveReview(run.id, inputs);
  await fetchPages();
};

const handleReject = async (run: GraphRun) => {
  await workspaceStore.rejectReview(run.id);
  await fetchPages();
};
</script>
<template>
  <NuxtLayout name="dashboard">
    <div class="grow w-full flex flex-col items-start justify-start gap-5">
      <p class="main-title">Inbox</p>
      <p class="body dimmed">
        This is where you will find your pending and completed spell run
        reviews.
      </p>

      <UDivider />

      <UHorizontalNavigation :links="inboxes" :ui="{ label: 'font-bold' }" />

      <div v-if="isFetchingPages" class="contents">
        <USkeleton v-for="i in 5" :key="i" class="w-full h-96 max-w-5xl" />
      </div>

      <div
        v-else-if="!pages?.length"
        class="flex flex-col w-full py-24 gap-2 items-center"
      >
        <UIcon name="i-ph-smiley-melting" class="text-gray-400 text-3xl" />
        <p class="subtitle">
          You have
          {{ tabId === 'todo' ? 'no pending reviews' : 'no completed tasks' }}
        </p>
      </div>
      <AppReviewItem
        v-for="graphRun in pages"
        v-else
        :key="graphRun.id"
        :item="graphRun"
        :type="tabId"
        :handle-reject="handleReject"
        :handle-resume="handleResume"
      />
    </div>
  </NuxtLayout>
</template>
